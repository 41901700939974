import React, {useEffect, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {theme} from 'common/theme/appminimal';
import {DrawerProvider} from 'common/contexts/DrawerContext';
import {ResetCSS} from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/AppMinimal/Navbar';
import KeyFeatures from 'containers/AppMinimal/KeyFeatures';
import ChooseUs from 'containers/AppMinimal/ChooseUs';
import Footer from 'containers/AppMinimal/Footer';
import Seo from 'components/seo';
import GlobalStyle, {
    AppWrapper,
    ContentWrapper,
    GradientWrapper,
} from 'containers/AppMinimal/app-minimal.style';
import NavbarOut from "../containers/AppMinimal/NavbarOut";

const Terms = () => {


    useEffect(() => {

    }, []);

    return (
        <ThemeProvider theme={theme}>
            <>
                <Seo title="Terms & Conditions"/>
                {/* end of head */}

                <ResetCSS/>
                <GlobalStyle/>
                {/* end of global and reset style */}

                {/* start app minimal landing */}
                <AppWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active" className="black-sticky">
                        <DrawerProvider>
                            <NavbarOut/>
                        </DrawerProvider>
                    </Sticky>
                    <ContentWrapper>
                        <section style={{background: "#1089ff", width: "100%", height: "100px"}}></section>
                        <section style={{width: "80%", margin: "auto", textAlign: "justify", paddingTop: "80px"}}>
                            <h1>Terms & Conditions</h1>
                            <h3>Please read with care the terms &amp; conditions for using this website</h3>
                            <h5>TERMS AND CONDITIONS OF USE</h5>
                            <p>Our company operates a SMS (Text messaging) service on the url www.secret-
                                sms.com letting individuals and companies acting in a private or anonymous
                                manner to send receive text messages with or without nicknames or sender
                                names</p>
                            <p>The objective of these terms and conditions of use help define how you, the
                                customer and user, can benefit from our services and also to define how we can
                                benefit from you using our services.</p>
                            <p>Our terms and conditions can be upgraded and/or modified at regular intervals
                                in which case we will inform our customers of the changes via email or on our
                                website.</p>
                            <p>This version of our terms and conditions of use is dated May 2023</p>

                            <h5>1. USING OUR SERVICE </h5>
                            <p className="c1">1.1 When sending an Message Text using this website, you will be asked for
                                your email address, your acceptance of our terms of use, the mobile phone
                                number you want to send an SMS to and your payment information. When you
                                have successfully completed these steps, your account will be created and a
                                new phone number will be attributed to you. If you make an account through the
                                24-hour trial, you will be subscribed to our services basic subscription and you
                                will have access to the dashboard on which you can receive unlimited SMS and
                                send up to 50 SMS weekly. The text message contained in the SMS and the
                                sender name are customizable. You may or may not disclose your identity to
                                the recipients of the SMS you are sending with secret-sms. Secret-SMS is
                                exclusively subscription-based when users make an account through the 24-
                                hour trial. </p>
                            <p>In an effort to reduce the number of duplicate secret-sms.com accounts we use
                                cookies to display some pages differently to users that have already made an
                                SMS account in the past. </p>
                            <p>1.2 If you choose to subscribe to our services through the FREE / 24h trial
                                (which allows you to send two SMS through our website). After 24 hours, you
                                will be subscribed to our services for an amount of $14,99 per month, unless
                                you unsubscribe before the end of the trial period. We will send some
                                notifications to your email address, if you provided a valid email address when
                                creating your account, before the end of the 24 hours trial to prompt you to
                                cancel your subscription if you wish to do it. With the $14,99 per month
                                subscription, you will be able to send 50 SMS per week through Secret-SMS. </p>
                            <p>If after sending your first two SMS, you do not want to wait for 24 hours, you
                                can upgrade to our Basic Plan right away, directly from your profile. You will
                                then be charged Secret-SMS monthly which will allow you to receive unlimited
                                SMS and to send 50 SMS per week.</p>
                            <p>1.3 We reserve the right to manually or automatically assess if a message is
                                deemed to go against our terms and conditions. When using any part of our
                                services (Secret-SMS) you are forbidden to: </p>
                            <p>1.3.1 Use the website and its services unlawfully.</p>
                            <p>1.3.2 Break any law, anywhere in the world through the services available on
                                our website.</p>
                            <p>1.3.3 Violate any of the terms readable on this web page.</p>
                            <p>1.3.4 Use our services or website to send any threatening, abusive, vulgar,
                                harassing, pornographic, profane, or any kind of unlawful information.</p>
                            <p>1.3.5 Sell, distribute, rent, loan, lease, transfer, distribute, or sub-license all or
                                part of the content included on the secret-sms.com website or any of our
                                services.</p>
                            <p>1.3.6 Use our website or services to spam anyone or any company.</p>
                            <p>1.3.7 Denigrate, disturd, or diminish, our activity in behavior that would damage
                                our brand or services reputation by using our services or on any other platform.</p>
                            <p>1.3.8 Use of services to try to scam people or to conduct any kind of phishing
                                operations.</p>
                            <p>1.3.9 Use our services to impersonate a company that you do not own or to
                                send SMS that use a copyrighted trademark as a sender name.</p>
                            <p>If we detect that any customer breaks any of this terms and conditions of use
                                present on this page, we will suspend their account. We reserve the right to
                                take any legal action against any of our users in the event of identity theft or in
                                the event of any other serious crime.</p>
                            <p>If the text messages you are trying to send are against any of the terms and
                                conditions of use of this website, they will not be sent. However, your paid
                                subscription to our services will not be canceled until you contact us to ask for
                                cancellation. You will still be able to send legitimate text messages according to
                                our rules. Breaking any of the terms and conditions of use present on this page,
                                you will not be eligible for any kind of refund for the months you were charged
                                for before the cancellation of your subscription.</p>
                            <p>Any SMS or message text that even remotely looks like phishing or scam will
                                not be sent.</p>
                            <h5>2. PAYMENT </h5>
                            <p>2.1 The pricing of our website’s services is: </p>
                            <p>The customer can try our services for $0.95 usd (called the 24h trial on our
                                website). After the 24 hours trial period and if the customer did not terminate
                                their account during the 24 hours trial period, the customer will be subscribed to
                                our services for an amount of $4.99 per week. If the customer does not
                                terminate their subscription to our services, the subscription will be
                                automatically renewed every month and the card the customer used to create
                                their account will be charged.</p>
                            <p>2.2 The prices above are inclusive of all taxes.</p>
                            <p>2.3 Our website is equipped with a secure online payment system, which
                                encrypts the transmission of the customers payment information. We use an
                                external service to process payments called &quot;Stripe&quot;. Payments are made
                                through our website in euros or in dollars by credit card (supposedly working
                                with all the credit cards compatible with Stripe).</p>

                            <h5>3. RIGHT OF CANCELLATION </h5>

                            <p>3.1 When the 24h trial ends, you have a right to cancel the agreement within 14
                                (fourteen) days after you used our services for the first time.</p>
                            <p>3.2 You can request a cancellation by sending an email to our support from the
                                email you used to register an account on our website. We will send you a
                                confirmation to the email address you provided. You can write to support@secret-
                                sms.com </p>
                            <p>3.3 If your cancellation is accepted by us you will be refunded the amount you
                                paid us for the 24h trial on the credit card you used to pay for our service. This
                                refund will be credited to your card within 5 to 10 days after our support
                                received your withdrawal request.</p>
                            <p>3.4 If you use our services after the 24h trial period, you understand that we will
                                not be able to refund the amount paid for the 24h trial. In this case, the request
                                for your right of cancellation will be treated by us as a request for the
                                cancellation of your subscription and this cancellation will be in effect at the end
                                of the month in which you make the cancellation request.</p>
                            <p>3.5 You will lose your right of cancellation, as well as your right to a refund if
                                you :</p>
                            <p>Try to send SMS that could be considered phishing (for example but not limited
                                to: using the name of any company that is not your own, a hospital, a bank,
                                carrier, etc...).</p>
                            <p>Try to send too many SMS to the same phone number (spam).</p>
                            <p>Do not comply with any of our terms and conditions.</p>

                            <h5>4. TERMINATION OF SECRET-SMS ACCOUNTS</h5>
                            <p>4.1 The termination of an SECRET-SMS account is allowed at any time for any
                                of our website’s users. The people using our services can close their accounts
                                at any time by clicking the unsubscribe button accessible from the secret-
                                sms.com main menu or from any subscribed users’ secret-sms.com dashboard.
                                You can also request to unsubscribe by contacting us directly via email to this
                                address: support@secret-sms.com . Upon clicking any of the unsubscribe
                                buttons, the SECRET-SMS users will be redirected to a web page asking for the
                                email address used when subscribing to our services. On this page a button
                                unsubscribe will be present upon clicking it, and given the fact that the email
                                address is indeed linked to a working SMS secret-sms.com account, the
                                subscription of the user will be terminated. The termination of the subscription
                                will take place at the end of the month during which the termination of the
                                subscription request is made or at the end of the 24 hours trial period. If users
                                unsubscribe through their secret-sms.com dashboard before the end of the trial
                                period they will be instantly unsubscribed from our services and their account
                                will be closed.</p>
                            <p>If users directly contact us via email to unsubscribe, they will be manually
                                unsubscribed. We will do our best to ensure that manual unsubscriptions
                                requests are treated within the 24 hours following the request. However, manual
                                unsubscriptions are not instantaneous so there might be delays. If the
                                unsubscribe request was made before the end of the 24h trial and that the
                                customer was charged for the first month they will be refunded for this month.</p>

                            <p>4.2 The termination of an secret-sms.com account can happen from our end.
                                We have the right to terminate any of our user’s accounts. In case of an account
                                termination from our end, we might inform the user through the email address
                                they provided when registering for our services. If we get the knowledge that
                                any of our users broke any of our terms and conditions of use, including but not
                                limited to the section 1.3’s content, we reserve the right to suspend any account
                                during an inquiry is made or to terminate any account verified to have breached
                                any of our terms and conditions of use. </p>
                            <p>4.3 Any kind of account termination does not entitle any of SMS secret-
                                sms.com users to a refund of any kind for the services already paid for by any
                                user.</p>
                            <h5>5. INTELLECTUAL PROPERTY </h5>
                            <p>5.1 All intellectual property rights of this website and of what&#39;s contained in this
                                website are owned by Mobi Technologies LLC </p>
                            <h5>6. LIABILITY AND WARRANTY </h5>
                            <p>6.1 We shall make reasonable efforts to ensure 24/7 availability of our services,
                                but shall not be liable for any unavailability due to technical constraints and/or
                                bugs, and maintenance operations, which shall not give rise to any right to any
                                refund whatsoever.</p>
                            <p>6.2 Our website secret-sms.com may contain links to other websites that are
                                not edited or controlled by us. Therefore, we can not be held responsible for the
                                content or proper functioning of these external websites under any
                                circumstances.</p>
                            <p>6.3 Every single SMS that anyone tries to send using our services might be
                                analyzed by a human to prevent phishing, scams, or anything illegal from being
                                sent through secret-sms.com.</p>
                            <h5>7 COMMUNICATION. </h5>
                            <p>Any communication to be addressed to us secret-sms.com will be sent:</p>
                            <p>By email at: <b>support@secret-sms.com</b></p>
                            <h5>Legal Disclaimer: </h5>
                            <p>Our website and services should only be used for legitimate and lawful
                                purposes respective to the region of the world you are using our services from
                                and to the region of the world the person you are sending the message to is
                                from. </p>
                            <p>We will do our best to detect any unlawful activity (mentioned in the 1.3 of these
                                terms and conditions of use) by the users of our services and website. If we do
                                detect such unlawful activity we will delete the user account concerned and we
                                will comply with any request from law officials to disclose information to them.</p>
                            <p>Any trademark mentioned on this website is the property of its respective
                                owners. These trademarks are only used for the purpose of describing how our
                                service works. </p>
                        </section>
                    </ContentWrapper>
                    <GradientWrapper>
                        <div style={{'height': '100px'}}></div>
                        <Footer/>
                    </GradientWrapper>
                </AppWrapper>
                {/* end of app minimal landing */}
            </>
        </ThemeProvider>
    );
};
export default Terms;
